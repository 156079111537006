import axios from 'axios';
import global from '../Global.js'
// 基础方法进行封装
function getApiBasic(url, postdata) {
    return  axios.post(url, postdata).then(function(data){
        let json = data.data;
        return json
    },function(response){
        console.info(response);
    })
}

// 调用的api改写成方法
export function worldDetail(postdata) {
        let url = headapi + 'worldDetail';
        return getApiBasic(url,postdata);
}

export function editbasicinfo(postdata) {
    let url = headapi + 'editbasicinfo';
    return getApiBasic(url,postdata);
}
export function modPwd(postdata) {
    let url = headapi + 'modPwd';
    return getApiBasic(url,postdata);
}
export function logout(postdata) {
    let url = headapi + 'logout';
    return getApiBasic(url,postdata);
}

// 调用的api改写成方法
export function testTable(postdata) {
    let url = headapi + 'testTable';
    return getApiBasic(url,postdata);
}
export function testSelect(postdata) {
    let url = headapi + 'testSelect';
    return getApiBasic(url,postdata);
}

export function testPic(postdata) {
    let url = headapi + 'testPic';
    return getApiBasic(url,postdata);
}


// 正式接口

// 获取验证图片
export function GenVerifyPic(postdata) {
    let url = headapi + 'v1/Auth/GenVerifyPic';
    return getApiBasic(url, postdata);
}

// 手机获取验证码
export function GetPhoneVFCode(postdata) {
    let url = headapi + 'v1/Auth/GetPhoneVFCode';
    return getApiBasic(url, postdata);
}

export function SignIn(postdata) {
    let url = headapi + 'v1/Auth/SignIn';
    return getApiBasic(url,postdata);
}
// 手机登录
export function PhoneSignIn(postdata) {
    let url = headapi + 'v1/Auth/PhoneSignIn';
    return getApiBasic(url,postdata);
}

// 用户微信绑定
export function WXBind(postdata) {
    let url = headapi + 'v1/Auth/WXBind';
    return getApiBasic(url,postdata);
}

// 用户微信登录
export function WXSignIn(postdata) {
    let url = headapi + 'v1/Auth/WXSignIn';
    return getApiBasic(url,postdata);
}

// 用户微信解除绑定
export function WXUnbind(postdata) {
    let url = headapi + 'v1/Auth/WXUnbind';
    return getApiBasic(url,postdata);
}


// Weixin

// 预约课程
export function OderAddByVipUser(postdata) {
    let url = headapi + 'v1/Weixin/OderAddByVipUser';
    return getApiBasic(url,postdata);
}

// 取消预约
export function OderCancelByVipUser(postdata) {
    let url = headapi + 'v1/Weixin/OderCancelByVipUser';
    return getApiBasic(url,postdata);
}
// 预约记录查询
export function OrderListQuery(postdata) {
    let url = headapi + 'v1/Weixin/OrderListQuery';
    return getApiBasic(url,postdata);
}
// 可预约店铺信息查询
export function OrderShopQuery(postdata) {
    let url = headapi + 'v1/Weixin/OrderShopQuery';
    return getApiBasic(url,postdata);
}
// 可预约课程列表查询(未完成)
export function CanOrderSchoolTimeTableListQuery(postdata) {
    let url = headapi + 'v1/Weixin/CanOrderSchoolTimeTableListQuery';
    return getApiBasic(url,postdata);
}
// 消费记录查询
export function VipUserConsumeListQuery(postdata) {
    let url = headapi + 'v1/Weixin/VipUserConsumeListQuery';
    return getApiBasic(url,postdata);
}
// 用户自身信息查询
export function VipUserSelfQuery(postdata) {
    let url = headapi + 'v1/Weixin/VipUserSelfQuery';
    return getApiBasic(url,postdata);
}

// 某日课程表查询
export function SchoolTimeQueryByDate(postdata) {
    let url = headapi + 'v1/Weixin/SchoolTimeQueryByDate';
    return getApiBasic(url,postdata);
}

// 通过session查询用户自身信息
export function ManagerSelfQuery(postdata) {
    let url = headapi + 'v1/User/ManagerSelfQuery';
    return getApiBasic(url,postdata);
}

// 课程表预览
export function SchoolTimetablePreview(postdata) {
    let url = headapi + 'v1/SchoolTimeTable/SchoolTimetablePreview';
    return getApiBasic(url, postdata);
}
// CleCk查询
export function VipUserCleCkSum(postdata) {
    let url = headapi + 'v1/Weixin/VipUserCleCkSum';
    return getApiBasic(url, postdata);
}
// 查询下周是否有课
export function QueryNextWeek(postdata) {
    let url = headapi + 'v1/Weixin/QueryNextWeek';
    return getApiBasic(url, postdata);
}

// 用户店铺信息查询
export function MyShopQuery(postdata) {
    let url = headapi + 'v1/Weixin/MyShopQuery';
    return getApiBasic(url, postdata);
}

// 首页–上部个人课程统计卡片
export function PersonCardStatistics(postdata) {
    let url = headapi + 'v1/Weixin/PersonCardStatistics';
    return getApiBasic(url, postdata);
}

// 首页–下部个人课程统计列表
export function PersonClassStatistics(postdata) {
    let url = headapi + 'v1/Weixin/PersonClassStatistics';
    return getApiBasic(url, postdata);
}

// 首页–中部–三个个人上课情况月统计表
export function PersonMonthStatistics(postdata) {
    let url = headapi + 'v1/Weixin/PersonMonthStatistics';
    return getApiBasic(url, postdata);
}

// 课程详细信息上部卡片
export function PersonClassDetail(postdata) {
    let url = headapi + 'v1/Weixin/PersonClassDetail';
    return getApiBasic(url, postdata);
}

// 微信端课程统计页面,带分页
export function PersonClassList(postdata) {
    let url = headapi + 'v1/Weixin/PersonClassList';
    return getApiBasic(url, postdata);
}

// 某课程动作评分查询
export function PersonScoreClassQuery(postdata) {
    let url = headapi + 'v1/Weixin/PersonScoreClassQuery';
    return getApiBasic(url, postdata);
}

// 训练目标总览列表
export function PersonTtolistQuery(postdata) {
    let url = headapi + 'v1/Weixin/PersonTtolistQuery';
    return getApiBasic(url, postdata);
}

// 训练目标详情页面
export function PersonTtdStaticsQuery(postdata) {
    let url = headapi + 'v1/Weixin/PersonTtdStaticsQuery';
    return getApiBasic(url, postdata);
}
//可预约课程列表查询
export function MyDailyTrainOverviewQuery(postdata) {
    let url = headapi + 'v1/Weixin/MyDailyTrainOverviewQuery';
    return getApiBasic(url, postdata);
}
//日常训练详情列表查询
export function MyDailyTrainDetailListQuery(postdata) {
    let url = headapi + 'v1/Weixin/MyDailyTrainDetailListQuery';
    return getApiBasic(url, postdata);
}
//上课统计某课程详细页面--上部课程信息
export function WxTplClassOverDetail(postdata) {
    let url = headapi + 'v1/Weixin/WxTplClassOverDetail';
    return getApiBasic(url, postdata);
}
//
export function WxTplScoreClassQuery(postdata) {
    let url = headapi + 'v1/Weixin/WxTplScoreClassQuery';
    return getApiBasic(url, postdata);
}
/**
 * 体测接口
 */
// 个人测评记录列表查询FtoDetailQuery
export function PersonFtoList(postdata) {
    let url = headapi + 'v1/Weixin/PersonFtoList';
    return getApiBasic(url, postdata);
}
// 个人测评记录列表查询FtoDetailQuery
export function FtoDetailQuery(postdata) {
    let url = headapi + 'v1/FitnessTest/FtoDetailQuery';
    return getApiBasic(url, postdata);
}
// 体测实例总览单条查询
export function FtoOverviewQuery(postdata) {
    let url = headapi + 'v1/FitnessTest/FtoOverviewQuery';
    return getApiBasic(url, postdata);
}
// 入会体测-可预约时间列表查询
export function CanOrderDateQuery(postdata) {
    let url = headapi + 'v1/FitnessTest/CanOrderDateQuery';
    return getApiBasic(url, postdata);
}
// 小飞龙微信端预约体侧未登录用户简单查询店铺信息
export function ShopQueryByUnLoginLoalLoutUser(postdata) {
    let url = headapi + 'v1/Shop/ShopQueryByUnLoginLoalLoutUser';
    return getApiBasic(url, postdata);
}
// 手机获取验证码
export function NotUserGetPhoneVFCode(postdata) {
    let url = headapi + 'v1/Auth/NotUserGetPhoneVFCode';
    return getApiBasic(url, postdata);
}
// 入会体测预约
export function OrderFitnessTestAdd(postdata) {
    let url = headapi + 'v1/FitnessTest/OrderFitnessTestAdd';
    return getApiBasic(url, postdata);
}
// 入会体测分组唯一分组信息查询
export function FTGroupTypeIsOrderOneQuery(postdata) {
    let url = headapi + 'v1/FitnessTest/FTGroupTypeIsOrderOneQuery';
    return getApiBasic(url, postdata);
}
// 我的入会体测预约记录查询
export function MyOrderFitnessTestListQuery(postdata) {
    let url = headapi + 'v1/FitnessTest/MyOrderFitnessTestListQuery';
    return getApiBasic(url, postdata);
}
// 入会体测预约取消
export function MyOrderFitnessTestCancel(postdata) {
    let url = headapi + 'v1/FitnessTest/MyOrderFitnessTestCancel';
    return getApiBasic(url, postdata);
}
// 我的入会体测预约记录详情
export function MyOrderFitnessTestDetailList(postdata) {
    let url = headapi + 'v1/FitnessTest/MyOrderFitnessTestDetailList';
    return getApiBasic(url, postdata);
}
// 我的入会体测预约记录查询凭借openId （微信端）
export function MyOrderFitnessTestListQueryByOpenId(postdata) {
    let url = headapi + 'v1/FitnessTest/MyOrderFitnessTestListQueryByOpenId';
    return getApiBasic(url, postdata);
}
// 我的入会体测预约登出（微信端）
export function MyOrderFitnessTestLogOut(postdata) {
    let url = headapi + 'v1/FitnessTest/MyOrderFitnessTestLogOut';
    return getApiBasic(url, postdata);
}
// 根据code查询openId和手机号（微信端）
export function GetOpenIdAndPhoneByCode(postdata) {
    let url = headapi + 'v1/FitnessTest/GetOpenIdAndPhoneByCode';
    return getApiBasic(url, postdata);
}

/**
 *
 *会员充值
 *
 */
// 订单查询
export function ChargeInfoQuery(postdata) {
    let url = headapi + 'v1/WxPay/ChargeInfoQuery';
    return getApiBasic(url, postdata);
}
// 当前优惠活动查询
export function CurrentPromotionsQuery(postdata) {
    let url = headapi + 'v1/WxPay/CurrentPromotionsQuery';
    return getApiBasic(url, postdata);
}
// JSAPI下单
export function JsApiPrepayRequest(postdata) {
    let url = headapi + 'v1/WxPay/JsApiPrepayRequest';
    return getApiBasic(url, postdata);
}
// 处理微信支付通知
export function NotifyInfoDeal(postdata) {
    let url = headapi + 'v1/WxPay/NotifyInfoDeal';
    return getApiBasic(url, postdata);
}
// 手机号对应商家查询
export function PhoneRelationShopQuery(postdata) {
    let url = headapi + 'v1/WxPay/PhoneRelationShopQuery';
    return getApiBasic(url, postdata);
}
// 微信端用户查询自身充值记录
export function MyChargeInfoListQuery(postdata) {
    let url = headapi + 'v1/WxPay/MyChargeInfoListQuery';
    return getApiBasic(url, postdata);
}
// 用户邀请码查询
export function VipUserSelfInviteCodeQuery(postdata) {
    let url = headapi + 'v1/Weixin/VipUserSelfInviteCodeQuery';
    return getApiBasic(url, postdata);
}
// 优惠活动总览Tab页查询
export function CurrentPromotionsTabQuery(postdata) {
    let url = headapi + 'v1/WxPay/CurrentPromotionsTabQuery';
    return getApiBasic(url, postdata);
}

/**
 *
 *心率系统接口
 *
 */
// 首页–中部–三个个人上课情况月统计表
export function GetUserHistoryInfoByDuId(postdata) {
    let url = headbpi + 'v1/OutService/GetUserHistoryInfoByDuId';
    return getApiBasic(url, postdata);
}
// 微信端心率折线
export function GetHrValueInfoByDuId(postdata) {
    let url = headbpi + 'v1/OutService/GetHrValueInfoByDuId';
    return getApiBasic(url, postdata);
}
//微信端课程运动强度柱状图信息
export function GetSportPercentByDuId(postdata) {
    let url = headbpi + 'v1/OutService/GetSportPercentByDuId';
    return getApiBasic(url, postdata);
}
