<template>
  <div id="mainPage">
    <div class="topBC">
      <!--顶部背景图片-->
      <img src="../static/images/logo_memberTest.png" width="240px" height="98px"/>
    </div>

    <!--查询区域-->
    <div class="context">
      <div class="indexNaviLine" @click="goPage('testMembership')">
        <img class="indexIcon" src="../static/images/logo_goAllOutMan_run.png"/>
        <span class="indexTitle">入会测评预约</span>
      </div>

      <div class="naviLine" @click="goPage('testMembershipQuery')">
        <img class="icon" src="../static/images/icon_mark_search.png"/>
        <span class="title">查询测评成绩</span>
      </div>

      <div class="naviLine" @click="goPage('testMembershipAddress?pageType=2')">
        <img class="icon" src="../static/images/icon_mark_exCalm.png"/>
        <span class="title">入会测评须知</span>
      </div>

      <div class="naviLine" v-if="false">
        <img class="icon" src="../static/images/icon_mark_question.png"/>
        <span class="title">常见问题</span>
      </div>

      <div class="naviLine" @click="goPage('testMembershipAddress')">
        <img class="icon" src="../static/images/icon_mark_location.png"/>
        <span class="title">场馆地图</span>
      </div>
      <div class="logout" v-if="bindVisible">
        <em class="button" @click="unbind()">登出</em>
        <span>{{ getPhone() }}</span>
      </div>
    </div>

    <alert ref="alertPart"></alert>
  </div>
</template>

<script>
import {
  GetOpenIdAndPhoneByCode,
  MyOrderFitnessTestLogOut,
} from '../api/getApiRes.js'

import bottomTab from '../components/bottomTab'
import alert from '../components/alert'

let qs = require('qs');
import Global from '../Global.js'

export default {
  data() {
    return {
      bindVisible: (localStorage.openId && localStorage.phone) ? true : false,
      form: {},
    }
  },
  mounted() {
    this.checkCode();
  },
  destroyed() {
  },
  watch: {
    $route(to) {
      if (to.name == 'testMemberNavi') {
        this.checkCode();
      }
    },
    'active'() {
      this.getList();
    }
  },
  methods: {
    getPhone() {
      return localStorage.phone.toString().substr(0, 3) + '****' + localStorage.phone.toString().substr(7);
    },
    alertInfo(info) {
      this.$refs.alertPart.openSimpleDialog(info);
    },
    goPage(panel) {
      this.$router.push({
        path: panel, query: {}
      });
    },
    filterTime: function (value) {
      if (!value) {
        return 0;
      } else {
        return dateFormatWithoutZone(value, 16);
      }
    },
    unbind() {
      this.$confirm('您确定要退出登录吗').then(({result}) => {
        if (result) {
          let that = this;
          let param = {
            openId: localStorage.openId ? localStorage.openId : ''
          };
          MyOrderFitnessTestLogOut(qs.stringify(param)).then(res => {
            let json = res;
            if (json.Code == 0) {
              localStorage.removeItem('openId');
              localStorage.removeItem('phone');
              that.bindVisible = localStorage.phone ? true : false;
            }
          })
        }
      });
    },
    // 获取CODE
    checkCode() {
      if (!localStorage.openId) {
        //取到openId
        if (this.getUrlKey('state') == 123) {
          let code = this.getUrlKey('code');
          console.log(code);
          //通过openCode查询openId & phone
          this.getOpenId(code);
        } else {
          this.getOpenCode();
        }
      }
    },
    // 获取公众号ID
    getOpenCode() {
      console.log('获取公众号ID');
      let origin = location.href;//http://wx.xfeilong.com
      let urlNow = encodeURIComponent(origin);
      let scope = 'snsapi_userinfo';//snsapi_userinfo 静默授权 用户无感知
      let appid = 'wx36cb8b6661d71811';//正式
      let state = '123';
      let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + urlNow + '&response_type=code&scope=' + scope + '&state=' + state + '#wechat_redirect';

      self.location = url;
    },
    getUrlKey(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
    },
    getOpenId(openCode) {
      let that = this;
      let param = {
        code: openCode
      };
      let postdata = qs.stringify(param);
      GetOpenIdAndPhoneByCode(postdata).then(res => {
        let json = res;
        if (json.Code == 0) {
          if (json.OpenId != '') {
            localStorage.openId = json.OpenId;
          } else {
            localStorage.removeItem('openId');
          }
          if (json.Phone != '') {
            localStorage.phone = json.Phone;
          } else {
            localStorage.removeItem('phone');
          }
        } else {
          localStorage.removeItem('phone');
          localStorage.removeItem('openId');
        }
      })
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == '') {
        vm.getList();
      }
    });
  },
  components: {
    bottomTab, alert
  }
}
</script>

<style scoped>
#mainPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: url('../static/images/bc_memberTest_navi.png') top center / cover;
  overflow-y: scroll;
}

.topBC {
  height: 130px;
  width: 100%;
  margin-top: 42px;
  text-align: center;
}

.topBC img {
  margin-top: 21px;
}

.logout {
  width: 50%;
  margin-left: 23%;
  height: 40px;
  margin-top: 55px;
}

.logout .button {
  width: 50px;
  font-size: 14px;
  height: 25px;
  background: rgba(174, 161, 161, 0.68);
  display: block;
  text-align: center;
  line-height: 25px;
  float: right;
  margin-right: 7%;
  margin-top: 3%;
  color: white;
  border-radius: 5px;
}

.logout span {
  width: 45px;
  height: 20px;
  float: right;
  margin-top: 3%;
  margin-right: 45px;
  color: white;
}

.context {
  width: 90%;
  margin: 50px auto;
  border-radius: 10px;
  padding-bottom: 30px;
}

.context .indexNaviLine {
  background: #E75296;
  width: 80%;
  margin-left: 10%;
  text-align: center;
  border-radius: 150px;
  line-height: 70px;
  height: 70px;
}

.indexNaviLine .indexIcon {
  width: 51px;
  height: 50px;
  margin-top: 9px;
  display: block;
  float: left;
  margin-left: 5%;
}

.indexNaviLine .indexTitle {
  width: 100%;
  margin-left: -15%;
  font-size: 24px;
  color: white;
}

.context .naviLine {
  background-image: linear-gradient(90deg, #ffa310 0%, rgba(255, 255, 255, 0.39) 70%);
  border: 1px dotted rgba(255, 255, 255, 0.51);
  border-radius: 35px;
  width: 80%;
  text-align: center;
  margin-left: 10%;
  margin-top: 30px;
  line-height: 60px;
  height: 60px;
}

.naviLine .icon {
  width: 50px;
  height: 50px;
  margin-top: 4px;
  float: left;
  margin-left: 4px;
}

.naviLine .title {
  font-size: 24px;
  color: white;
  margin-left: -15%;
}


/*响应式调整*/
@media only screen and (max-width: 320px) {
}

@media (min-width: 321px) and (max-width: 344px) {
}

@media (min-width: 345px) and (max-width: 360px) {
}

@media (min-width: 361px) and (max-width: 375px) {
}

@media (min-width: 376px) and (max-width: 396px) {
}

@media (min-width: 397px) and (max-width: 414px) {
}

@media (min-width: 415px) and (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 640px) {
}
</style>
